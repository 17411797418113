@import "./colors.scss";

#projects {
	background-image: linear-gradient(var(--bg-secondary-color) .1em, transparent 0.1em), linear-gradient(90deg, var(--bg-secondary-color) .1em, transparent .1em);
	background-size: 15vw 100%;
	
	.hoveredImg {
		position: fixed;
		pointer-events: none;
		top: 50%;
		left: 16em;
		z-index: 1;
		max-width: 60vw;
		max-height: 80vh;
		filter: brightness(0.4);
		border: none;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 2;

		.project {
			margin: 0;
			color: $textGray;
			width: fit-content;


			.row {
				align-items: flex-start;
				margin-left: -26px;

			}

			&-order {
				font-size: 0.8rem;
				font-variant: small-caps;
				font-weight: 400;
				letter-spacing: 0.05em;
				margin: 0 10px 0 0;
				padding: 0;
				font-family: 'Roboto';
				
			}

			&-name {
				font-family: 'Six Caps', sans-serif;
				font-size: clamp(6rem, 8rem, 9rem);
				text-transform: uppercase;
				letter-spacing: 3px;
				margin: -3px 5px 25px 0;
				line-height: clamp(6rem, 7rem, 9rem);

			}

			&-slug {
				line-height: 12px;
				margin: 0;
				letter-spacing: 0px;
			}

			&-stack {
				display: flex;
				flex-wrap: wrap;
				position: relative;
				margin: 10px 0;
				padding: 0px;
				list-style: outside none none;
				

				&-item {
					font-size: 0.9rem;
					white-space: nowrap;
					margin: 0 5px 0 0;
					letter-spacing: 0px;
					color: var(--bg-secondary-color);
					font-variant: small-caps;
					font-weight: 700;
					padding: 2px 4px;
					background-color: var(--accent-light-color);
				}
			}
		}
	}
}

@media (max-width:425px) {
	#projects {
		background-size: 35vw 100%;

		.hoveredImg {
			left: 40px;
			max-width: 80vw;
			max-height: 80vh;
		}

		ul {
			.project {

				.row {
					margin-left: -5px;
					// justify-content: space-between;
					flex-direction: column;
				}

				&-order {
					margin-top: 8px;
				}

				&-slug {
					line-height: 20px;
					margin-bottom: 10px;
					hyphens: auto;
					word-break: break-word;
					text-align: left;
				}

				&-name {
					flex-grow: 2;
					margin: 0 0 10px 0;
					line-height: clamp(5rem, 6.3rem, 8rem);
					font-size: clamp(5rem, 6.5rem, 8rem)
				}
			}
		}
	}
}