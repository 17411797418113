@import "./colors.scss";

main>section {
  padding: 75px 75px 75px 14em;
  color: $darkGray;
}

.row {
  display: flex;
  flex-direction: row;
}


.section-title {
  font-size: 1.2rem;
  margin: 20px 0;
  // font-variant: small-caps;
  position: relative;
  z-index: 2;
  font-weight: 400;
}

.follow__active {
  width: 75px !important;
  height: 75px !important;
}

@media (max-width:425px) {
  main>section {
    padding: 35px;
    padding-left: 24px;
  }

  .section-title {
    line-height: 1.2rem;
  }
}