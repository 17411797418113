@import "./colors.scss";

$neonYellow: #fe019a;

.about {
    color: $gray;
    transform: matrix(1, 0, 0, 1, 0, 0);
    min-height: 70vh;

    .bio_text-container {
        max-width: 40vw;
        margin-left: 60px;

        p {
            font-size: 18px;
            margin: 0;
            line-height: 30px;

            .link {
                // text-decoration: underline;
                color: var(--accent-dark-color);
            }

            .shake {
                display: inline-block;
                transition: all 0.2s ease-in-out;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                perspective: 1000px;

                &:hover {
                    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
                }
            }

            .fun {
                font-family: "Sacramento", cursive;
                font-style: italic;
                font-size: 27px;
                padding: 0px 3px;
                color: $neonYellow;
                text-shadow:
                    1px 0px 4px lighten($neonYellow, 30%),
                    0px 0px 3px $neonYellow,
                    2px 3px 15px $neonYellow,
                    2px 0px 15px,
                    5px 0px 105px,
                    20px 0vw 70px $neonYellow,
                    10px 0vw 70px $neonYellow;
                animation: flicker 3s linear infinite;
            }
        }
    }

    #name {
        white-space: nowrap;
        backface-visibility: hidden;
        font-family: 'Six Caps', sans-serif;
        text-transform: uppercase;
        margin: 5px 0 0 0;
        transform-origin: bottom left;
        letter-spacing: 1px;
        color: $gray;
        font-size: 130px;
        line-height: 130px;

        span {
            display: inline-block;
        }

    }

    #hello {

        left: 0;
        font-family: "Sacramento", cursive;
        font-size: 2.5rem;
        margin: 0;
        transform: rotate(-6deg) translateY(20px);
        transform-origin: bottom left;

        span {
            display: inline-block; // if you remove, enter animation wont work
        }
    }
}


.bio {
    display: flex;
    flex-direction: row;
}

$check-size: 12px;

#bio-length-form {
    width: clamp(150px, 200px, 300px);
    padding-top: 10px;

    .fieldset {
        border: none;
        padding: 0;
        padding-top: $check-size * 2; // leave the size of the radio button to the top
    }

    .legend {
        font-size: 0.8rem;
        font-variant: small-caps;
        font-weight: 400;
        letter-spacing: 0.05em;
        margin: 0;
        padding: 0;
        text-transform: lowercase;
        font-family: 'Roboto';
        margin-bottom: 4px;
        color: var(--accent-dark-color);

    }

    .container {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        li {
            color: lighten($gray, 0.4);
            display: inline-block;
            position: relative;

            &:first-child {

                // "shortest"
                label {
                    letter-spacing: 0.01px;
                }
            }

            &:last-child {

                // "longest"
                label {
                    letter-spacing: 0.3em;
                }
            }

            input[type=radio] {
                position: absolute;
                visibility: hidden;
                width: 100%;
                height: 100%;

                &:checked {
                    ~label {
                        color: var(--accent-dark-color);

                        &::before {
                            border-color: var(--accent-dark-color);
                        }

                        &::after {
                            transform: translate(0%, 0%);
                            visibility: visible;
                        }
                    }
                }
            }

            label {
                display: block;
                position: relative;
                font-weight: 300;
                font-size: 0.8rem;
                letter-spacing: 0.2em;
                font-family: 'Roboto';
                cursor: pointer;
                transition: all 0.25s linear;

                &::before {
                    // using this as the outer radiobutton
                    content: "";
                    position: absolute;
                    height: $check-size;
                    width: $check-size;
                    background-color: transparent;
                    border: 1px solid $gray;
                    top: - (($check-size * 0.5) + $check-size);
                    left: 0px;
                    // transform: translateX(-50%);
                    border-radius: 100%;
                    transition: all 0.25s linear;

                }

                &::after {
                    // using this as the inner dot
                    content: "";
                    position: absolute;
                    background-color: var(--accent-dark-color);
                    top: - ($check-size + ($check-size * 0.5));
                    left: 0px;
                    width: $check-size + 2;
                    height: $check-size + 2;
                    transform: translate(0%, -100%);
                    border-radius: 100%;
                    transition: all 0.25s cubic-bezier(.68, -0.55, .27, 1.55);
                    visibility: hidden;


                }
            }
        }
    }

}


@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

@keyframes flicker {

    0%,
    19.999%,
    22%,
    62.999%,
    64%,
    64.999%,
    70%,
    100% {
        opacity: .99;

    }

    20%,
    21.999%,
    63%,
    63.999%,
    65%,
    69.999% {
        opacity: 0.4;
    }
}


@media (max-width: 425px) {
    .bio {
        flex-direction: column;
    }

    /* smartphones, iPhone, portrait 480x320 phones */
    .about {

        #hello {
            font-size: 2rem;
        }

        #name {
            max-width: 100%;
            white-space: normal;
        }

        .bio_text-container {
            max-width: 80vw;
            margin-left: unset;
            margin-top: 25px;
        }
    }
}

// @import "./screens.scss";